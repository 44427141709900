import React, { useEffect, useState } from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import LabelForInput from "../../../Components/Inputs/LabelForInput/LabelForInput";
import CommonButton from "../../../Components/Button/Button";
import { fromLatLng } from "react-geocode";
import { Button, Form, Input, Select, message } from "antd";
import { PERSONAL_DETAILS, PROFILE } from "../../../Constants/Router/RouterConstant";
import { fetch_address } from "../../../utils/SearchAddress";
import {
  EnvironmentOutlined,
  InboxOutlined,
  FileImageTwoTone,
  EyeTwoTone,
} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import { countryCode } from "../../../Constants/CountryCode/CountryCode";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setTempCompanyData } from "../../../Redux/Slice/TempCompanyData";
import { fetchDataForVerification } from "../../../Redux/Slice/VerifySlice";

function EditCompanyDetails() {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [location, setLocation] = useState("");
  const [membership, setMembership] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryCodes, setCountryCode] = useState("+65");
  const [countryCodeIso, setCountryCodeIso] = useState("SG");
  const [phone, setPhone] = useState("");
  const [exp_date, setExp_date] = useState("");
  const [value, setValue] = useState("");
  const [isVerified, setVerified] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const tempCompanyData = async () => {
      try {
        const response = await axios.get("/sa_fetch_company");
        const userData = response.data.response;
        console.log(userData);
        setPhone(userData.mobile_number);
        setMembership(userData.is_premium_member);
        setExp_date(userData.membership_exp_date);

        setFileList([
          {
            uid: "-1",
            name: "banner",
            status: "done",
            url: userData.cover,
          },
        ]);
        setFileList2([
          {
            uid: "-1",
            name: "enrollment_cert",
            status: "done",
            url: userData.enrollment_cert,
          },
        ])
        setVerified(userData.is_verified);
        setValue(userData.location);
        form.setFieldsValue({
          companyName: userData.name,
          email: userData.email,
          uen: userData.uen_number,
          unit: userData.unit_number,
          latestgrade: userData.latest_grade,
          location: userData.location,
          mobile_number: userData.mobile_number,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    tempCompanyData();
  }, []);

  const handleUpload = (info) => {
    setFileList(info.fileList);
  };

  const handleUpload2 = (info) => {
    if(isVerified){
      message.error("You are already verified");
      return;
    }
    setFileList2(info.fileList);
  }

  const currentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      fromLatLng(position.coords.latitude, position.coords.longitude).then(
        (json) => {
          setLocation(json.results[0].formatted_address);
        }
      );
    });
  };

  const fetchFunction = async (newValue) => {
    const res = await fetch_address(newValue);
    console.log(res);
  };

  let timer;
  const handleSearch = (newValue) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fetchFunction(newValue);
    }, 2000);
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };



  // {
  //   "name": "GP Agency",
  //   "email": "agency@test.com",
  //   "country_code": "+65",
  //   "country_code_iso": "SG",
  //   "mobile_number": "1111111111",
  //   "uen_number": "12345",
  //   "latest_grade": "Competent",
  //   "unit_number": "12345",
  //   "location": "434 HOUGANG AVENUE 8 A & J CHILD CARE CENTRE SINGAPORE 530434",
  //   "cover": {
  //   "name": "IMG_8160.JPG",
  //   "size": "23.438KB"
  //   }
  //   }
  const onFinish = async (values) => {

    try {
     const form = new FormData();
      form.append("name", values.companyName);
      form.append("email", values.email);
      form.append("country_code", countryCodes);
      form.append("country_code_iso", countryCodeIso);
      form.append("mobile_number", phone);
      form.append("uen_number", values.uen);
      form.append("latest_grade", values.latestgrade);
      form.append("unit_number", values.unit);
      form.append("location", value);
      if(fileList[0]?.originFileObj){
        form.append("cover", fileList[0]?.originFileObj);
      }
      if(!isVerified){
        form.append("enrollment_cert", fileList2[0]?.originFileObj ? fileList2[0]?.originFileObj : " ");
      }
      const res = await axios.post("/sa_update_company", form);

      message.success(res?.data?.message);
      dispatch(fetchDataForVerification());
      navigate(PROFILE)
    } catch (error) {
      message.error(error?.response?.data?.message);
      console.error("Error updating data:", error);
    }
  };

  return (
    <>
      <Navbar text="Edit Company Details" />
      <div className="flex justify-center items-center pt-10 w-80 m-auto flex-col gap-5 pb-14">
        <Form className="w-80" form={form} onFinish={onFinish}>
          <div className="w-full">
            <LabelForInput text="Membership Type" />
            <Form.Item name="membership">
              <div className="flex items-center justify-between">
                <Button className="bg-secondary" type="primary">
                  {membership ? "Premium" : "Basic"}
                </Button>
                <h3>Expire on {exp_date}</h3>
              </div>
            </Form.Item>
          </div>
          <div className="w-full text-center flex flex-col gap-4 mb-4">
            <LabelForInput text="Upload Agency Banner" />
            <Dragger
              beforeUpload={() => {
                return false;
              }}
              maxCount={1}
              fileList={fileList}
              onChange={handleUpload}
              accept="image/*,.pdf"
              className="w-full"
              onPreview={(file) => {
                if (file.url) {
                  window.open(file.url, "_blank");
                } else {
                  const url = URL.createObjectURL(file.originFileObj);
                  window.open(url, "_blank");
                }
              }}
            >
              <p className="ant-upload-drag-icon">
                <FileImageTwoTone />
              </p>
              <p className="ant-upload-text">Upload Agency -JPG/PNG</p>
            </Dragger>
          </div>
          <div className="w-full">
            <LabelForInput text="Enter Company Name *" />
            <Form.Item
              name="companyName"
              rules={[
                {
                  required: true,
                  message: "Please Enter Company Name",
                },
              ]}
            >
              <Input className="h-12" placeholder="Enter Company Name" />
            </Form.Item>
          </div>
          <div className="w-full">
            <LabelForInput text="Email Address *" />
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please Enter Email Address",
                  pattern: new RegExp(
                    /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/
                  ),
                },
              ]}
            >
              <Input className="h-12" placeholder="Enter Email" />
            </Form.Item>
          </div>
          <div className="w-full">
            <LabelForInput text="Mobile Number *" />
            <Form.Item
              name="mobile_number"
              rules={[
                {
                  required: true,
                  message: "Please Enter Mobile Number",
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
            >
              <div className="flex w-full">
                <div className="w-[30%]">
                  <Select
                    value={countryCodeIso}
                    onChange={(e, str) => {
                      setCountryCodeIso(str.children);
                      setCountryCode(str.value);
                    }}
                    className="h-12"
                  >
                    {countryCode.map((item) => {
                      return (
                        <Select.Option value={item.code}>
                          {item.dial_code}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
                <Input
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  className="h-12 w-[95%]"
                  placeholder="Enter Mobile Number"
                />
              </div>
            </Form.Item>
          </div>
          <div className="w-full">
            <LabelForInput text="Enter Uen Number *" />
            <Form.Item
              name="uen"
              rules={[
                {
                  required: true,
                  message: "Please Enter UEN Number",
                  pattern: new RegExp(/^[0-9]+$/),
                },
              ]}
            >
              <Input className="h-12" placeholder={"Enter UEN Number"} />
            </Form.Item>
          </div>
          <div className="w-full">
            <LabelForInput text="Latest Grade *" />
            <Form.Item
              name="latestgrade"
              rules={[
                {
                  required: true,
                  message: "Please Select Latest Grade",
                },
              ]}
            >
              <Select
                placeholder={"Select"}
                className="h-12"
                options={[
                  {
                    label: "Competent",
                    value: "Competent",
                  },
                  {
                    label: "Non Competent",
                    value: "Non Competent",
                  },
                  {
                    label: "Exempted",
                    value: "Exempted",
                  },
                  {
                    label: "A",
                    value: "A",
                  },
                  {
                    label: "B",
                    value: "B",
                  },
                  {
                    label: "C",
                    value: "C",
                  },
                  {
                    label: "D",
                    value: "D",
                  },
                ]}
              />
            </Form.Item>
          </div>
          <div className="w-full">
            <LabelForInput text="Unit No" />
            <Form.Item
              name="unit"
              rules={[
                {
                  required: true,
                  message: "Please Enter Unit Number",
                },
              ]}
            >
              <Input placeholder="Unit Number" className="h-12" />
            </Form.Item>
          </div>
          <div className="w-full text-center flex flex-col gap-4 mb-4">
            <LabelForInput text="Upload Bizzle Enrollment Certificate" />
            <Dragger
              beforeUpload={() => {
                return false;
              }}
              maxCount={1}
              fileList={fileList2}
              onChange={handleUpload2}
              accept="image/*,.pdf"
              className="w-full"
              onPreview={(file) => {
                if (file.url) {
                  window.open(file.url, "_blank");
                } else {
                  const url = URL.createObjectURL(file.originFileObj);
                  window.open(url, "_blank");
                }
              }}
            >
              <p className="ant-upload-drag-icon">
                <FileImageTwoTone />
              </p>
              <p className="ant-upload-text">Upload Document -JPG/PDF</p>
            </Dragger>
          </div>
          <div className="w-full">
            <LabelForInput text="Location" />
            <Form.Item
              name="location"
              rules={[
                {
                  required: true,
                  message: "Please Select Location",
                },
              ]}
            >
              <Select
                showSearch
                value={value}
                placeholder={"Select Location"}
                className="h-12"
                defaultActiveFirstOption={false}
                suffixIcon={null}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                notFoundContent={null}
                options={(data || []).map((d) => ({
                  value: d.value,
                  label: d.text,
                }))}
              />
              <div
                onClick={currentLocation}
                className="text-secondary cursor-pointer"
              >
                <EnvironmentOutlined /> Current Location
              </div>
            </Form.Item>
          </div>
          {/* <div className="w-full text-center flex flex-col gap-4 mb-4">
            <Dragger
              beforeUpload={() => {
                return false;
              }}
              maxCount={1}
              fileList={fileList}
              onChange={handleUpload}
              accept="image/*,.pdf"
              className="w-full"
              onPreview={(file) => {
                const url = URL.createObjectURL(file.originFileObj);
                window.open(url, "_blank");
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a singleupload. Strictly prohibited from uploading
                company data or other banned files.
              </p>
            </Dragger>
          </div> */}
          <div className="w-full">
            <Form.Item>
              <CommonButton
                text="Submit"
                type="submit"
                color={"bg-secondary"}
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
}

export default EditCompanyDetails;
