// Desc: App View

export const DASHBOARD = "/dashboard";
export const ADHOC = "/adhoc";
export const JOB_DETAIL = "/job-detail";
export const ADD_JOB = "/add-job";
export const EDIT_JOB = "/edit-job";
export const PERMANENT = "/permanent";
export const PERMANENT_JOB_DETAIL = "/permanent-job-detail";
export const ADD_PERMANENT_JOB = "/add-permanent-job";
export const OFFICERS_CHAT = "/officers-chat";
export const SELECT_CARD = "/select-card";
export const TRANSACTION = "/transaction";
export const PROFILE = "/profile";
export const MANAGE_CARD = "/manage-card";
export const CREDIT_CARD = "/credit-card";
export const ABOUT = "/about";
export const TERMSANDCONDITION = "/terms-and-condition";
export const BLACKLIST = "/blacklist";
export const SCHEDULAR = "/schedular";
export const DISPUTE = "/dispute";
export const DISPUTE_DETAILS = "/dispute-details";
export const CHAT_SUPPORT = "/chat-support";
export const EDIT_PROFILE = "/edit-profile";
export const EDIT_COMPANY_DETAILS = "/edit-company-details";
export const ANNOUNCEMENT = "/announcement";








// Desc: Auth View
export const LOGIN = "/login";
export const MOBILE_VERIFICATION = "/mobile-verification";
export const OTP_VERIFICATION = "/otp-verification";
export const COMPANY_DETAILS = "/company-details";

export const PERSONAL_DETAILS = "/personal-details";
export const CARD_DETAILS = "/card-details";
