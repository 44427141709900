import axios from "axios";

export const fetch_address = async(value) => {
  const urlnew = `https://www.onemap.gov.sg/api/common/elastic/search?searchVal=${value}&returnGeom=Y&getAddrDetails=Y&pageNum=1`;

try{
    const res = await axios({
        method: 'GET',
        url: urlnew,
    
    })

    console.log(res)


}catch(err){
    console.log(err);
}
};
