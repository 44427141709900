import { ArrowLeftOutlined, FilterOutlined, FilterTwoTone, SearchOutlined } from "@ant-design/icons";
import { Button, Modal, message } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ADHOC, EDIT_JOB } from "../../Constants/Router/RouterConstant";
import axios from "axios";
import { Filter, SearchForJob } from "../../Assets/ExportedIcon/Icon";
import Search from "antd/es/input/Search";

function Navbar({ text, type, username, date ,id,officer}) {
  const navigate = useNavigate();
  const TypeFunction = (type) => {
    if (type === "job" || type === "upcoming") {
      return (
        <div className="flex gap-3">
          <button onClick={editJob} className="bg-[#fff] text-[black] px-7 py-2 rounded border-none">
            Edit Job
          </button>
          <button
            onClick={deleteJob}
            className="bg-[#e63f3f] text-[#fff] px-7 py-2 rounded border-none"
          >
            Delete Job
          </button>
        </div>
      );
    }
  };

  const editJob = () => {
    navigate(EDIT_JOB+"?job_token="+id,{
      state:{
        officer:officer
      }
    })
  }


  const deleteConfirmJob = async ()=>{
    try{
      const res = await axios.post("sa_delete_job",{
        job_token:id
      })
      message.success(res?.data?.message)
      if(res.data.status === true){
        navigate(ADHOC)
      }
    }catch(err){
      console.log(err)
    }
  }

  const deleteJob = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this job?",
      content: "Once deleted, you will not be able to recover this job",
      okText: "Yes",
      okButtonProps: { danger: true },
      cancelText: "No",
      onOk() {
        deleteConfirmJob();
      },
    });
  };
  return (
    <>
      <div className="h-24 w-full bg-secondary justify-between items-center px-2 text-white flex">
        <div className="flex-1 flex justify-start items-center h-full gap-2 px-3">
          <div>
            <ArrowLeftOutlined
              onClick={() => navigate(-1)}
              className="text-xl"
            />
          </div>
          <div className="relative flex-1">
            <div className="text-xl font-bold">{text}</div>
            {subHeading(type, username, date)}
          </div>
        </div>
        <div>{TypeFunction(type)}</div>
      </div>
    </>
  );
}

export default Navbar;

const subHeading = (type, username, date) => {
  if (type === "pastJob") {
    return (
      <div className="absolute flex gap-10">
        <div className="text-[#32C5FF] font-normal text-xs flex flex-row gap-3">
          Created by<span className="text-[#fff] font-bold">{username}</span>
        </div>
        <div className="text-[#32C5FF] font-normal text-xs flex flex-row gap-3">
          Created on<span className="text-[#fff] font-bold">{date}</span>
        </div>
      </div>
    );
  }
};
